import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  TabContent,
  Spinner,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import classnames from "classnames";
// Editable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { HeaderFormatter } from "../../helpers/methods";
import { get, del } from "../../helpers/api_helper";
import { BASE_URL } from "../../helpers/url_helper";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { toast } from "react-toastify";
import { downloadExcel } from "react-export-table-to-excel";
import moment from 'moment';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as XLSX from 'xlsx';  // Import XLSX library


const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Business Name", value: "contractor" },
      { label: "Contractor Name", value: "contact_name" },
      { label: "Category", value: "category_heading" },
      { label: "City", value: "city" },
      { label: "Phone", value: "phone" },
      { label: "Zip", value: "zip" },
    ],
  },
];

const Campaigns = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [isPending, setIsPending] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1);
  const [sizePerPage, setsizePerPage] = useState(20);
  const [Search_Text, setsearchText] = useState("");
  const [selected_id, setSelected_id] = useState(null);
  const [success_confirm, setSuccess_confirm] = useState(false);
  const [alert_confirm, setAlert_confirm] = useState(false);
  const [dynamic_title, setDynamic_title] = useState("");
  const [dynamic_description, setDynamic_description] = useState("");
  const [loaddata, setLoadData] = useState(false);
  // Pending Table

  const [pendingcampaigns, setPendingCampaigns] = useState([]);
  const [pendingtotalSize, setpendingtotalSize] = useState(0);
  const [pendingpage, setpendingpage] = useState(1);
  const [pendingsizePerPage, setpendingsizePerPage] = useState(20);
  const [pendingSearchText, setpendingsearchText] = useState("");

  const [pausedcampaigns, setPausedCampaigns] = useState([]);
  const [pausedtotalSize, setPausedtotalSize] = useState(0);
  const [pausedpage, setPausedpage] = useState(1);
  const [pausedsizePerPage, setPausedsizePerPage] = useState(20);
  const [pausedSearchText, setPausedsearchText] = useState("");
  const [downloading, setDownloading] = useState(false)

  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [search_by, setSearchby] = useState({
    label: "Search By",
    value: null,
  });

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("CampaignFilter")) {
      const obj = JSON.parse(localStorage.getItem("CampaignFilter"));
      setFilter(obj.filter);
      setSearch(obj.search);
      setSearchby(obj.search_by);
      loadData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1${obj.filter}`,
        sizePerPage,
        page,
        Search_Text
      );
      loadPendingData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=false${obj.filter}`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
      loadPausedData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2${obj.filter}`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
    } else {
      loadData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1`,
        sizePerPage,
        page,
        Search_Text
      );
      loadPendingData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=false`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
      loadPausedData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
    }
  }, []);

  function ClearFilter() {
    setFilter("");
    setSearch("");
    setSearchby({ label: "Search By", value: null });
    loadData(
      `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1`,
      sizePerPage,
      page,
      Search_Text
    );
    loadPendingData(
      `${BASE_URL}/api/tgcl/dashboard/contracts/?status=false`,
      pendingsizePerPage,
      pendingpage,
      pendingSearchText
    );
    loadPausedData(
      `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2`,
      pendingsizePerPage,
      pendingpage,
      pendingSearchText
    );
    localStorage.removeItem("CampaignFilter");
  }

  function FilterData(e, values) {
    let filter_data = "";
    if (!search_by.value) {
      toast.error("Select Search Field");
    } else {
      filter_data = `&${search_by.value}=${values.search}`;

      setFilter(filter_data);
      loadData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1${filter_data}`,
        sizePerPage,
        page,
        Search_Text
      );
      loadPendingData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=false${filter_data}`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
      loadPausedData(
        `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2${filter_data}`,
        pendingsizePerPage,
        pendingpage,
        pendingSearchText
      );
    }
  }
  function saveFilter_to_storage() {
    let filters = {
      filter: filter,
      search_by: search_by,
      search: search,
    };
    localStorage.setItem("CampaignFilter", JSON.stringify(filters));
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      // hidden: true
    },
    {
      dataField: "contractor",
      text: "Contractor",
      headerStyle: { width: "20%" },
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link to={`/edit-campaign/${row.id}`} className="me-3 text-dark">
          {(cellContent ? cellContent : "").substring(0, 30)}
        </Link>
      ),
      title: (cell) => `${cell ? cell : ""}`,
    },
    {
      dataField: "category",
      text: "Category",
      headerStyle: { width: "20%" },
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link to={`/edit-campaign/${row.id}`} className="me-3 text-dark">
          {cellContent.substring(0, 30)}
        </Link>
      ),
      title: (cell) => `${cell}`,
    },
    {
      dataField: "page_title",
      text: "Assigned City",
      headerStyle: { width: "20%" },
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) =>
        cellContent ? cellContent.substring(0, 30) : "",
      title: (cell) => `${cell}`,
    },
    {
      dataField: "phone",
      text: "Tracking Phone",
      headerStyle: { width: "20%" },
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) => cellContent,
      title: (cell) => `${cell}`,
    },
    {
      dataField: "zip",
      text: "Zip",
      headerStyle: { width: "5%" },
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: "5%" },
      align: "center",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) =>
        cellContent === 1 ? (
          <i className="fa fa-check text-success font-size-18"></i>
        ) : (
          <i className="fa fa-times text-danger font-size-18"></i>
        ),
    },
  ];

  const activeColumn = [
    ...columns,
    {
      dataField: "slug",
      text: "Analytics",
      // isDummyField: true,
      headerStyle: { width: "5%" },
      align: "center",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link
          to={{
            pathname: `/analytics/contractor-detail/${cellContent}`,
            campaignID: row.id,
            url: "/campaigns",
            campaignName: cellContent,
            page_name: "Campaigns",
          }}
          className="me-3 text-dark"
        >
          <i className="fas fa-chart-line font-size-18"></i>
        </Link>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-campaign/${row.id}`} className="me-3 text-primary">
            <i className="mdi mdi-pencil font-size-18"></i>
          </Link>
          <Link
            to="#"
            onClick={() => {
              setSelected_id(row.id);
              setAlert_confirm(true);
            }}
            className="text-danger"
          >
            <i className="mdi mdi-trash-can font-size-18"></i>
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const pendingColumn = [
    ...columns,
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-campaign/${row.id}`} className="me-3 text-primary">
            <i className="mdi mdi-pencil font-size-18"></i>
          </Link>
          <Link
            to="#"
            onClick={() => {
              setSelected_id(row.id);
              setAlert_confirm(true);
              setIsPending(true);
            }}
            className="text-danger"
          >
            <i className="mdi mdi-trash-can font-size-18"></i>
          </Link>
        </React.Fragment>
      ),
    },
  ];

  function loadData(url, sizePerPage, page, searchText) {
    let data = get(url, { headers: { "Content-Type": "application/json" } });
    data.then((response) => {
      setCampaigns(response.data.results);
      settotalSize(response.data.count);
      setpage(page);
      setsizePerPage(sizePerPage);
      setsearchText(searchText);
      setLoadData(true);
    });
  }

  function loadPendingData(url, sizePerPage, page, searchText) {
    let data = get(url, { headers: { "Content-Type": "application/json" } });
    data.then((response) => {
      setPendingCampaigns(response.data.results);
      setpendingtotalSize(response.data.count);
      setpendingpage(page);
      setpendingsizePerPage(sizePerPage);
      setpendingsearchText(searchText);
    });
  }

  function loadPausedData(url, sizePerPage, page, searchText) {
    let data = get(url, { headers: { "Content-Type": "application/json" } });
    data.then((response) => {
      setPausedCampaigns(response.data.results);
      setPausedtotalSize(response.data.count);
      setPausedpage(page);
      setPausedsizePerPage(sizePerPage);
      setPausedsearchText(searchText);
    });
  }

  function NoDataIndication() {
    if (loaddata && campaigns.length === 0) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      );
    }
  }

  function handleCampaignClicks(id, is_pending) {
    del(`${BASE_URL}/api/tgcl/dashboard/campaign/${id}/`, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}).then((res) => {
      if (res.status >= 400) {
        console.log("error");
      } else {
        if (activeTab === "2") {
          let new_page = pendingpage;
          if (pendingcampaigns.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1;
          }
          let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=false&page_size=${pendingSearchText}&page=${new_page}`;
          url = filter !== "" ? url + `${filter}` : url;
          loadPendingData(url, pendingSearchText, new_page, pendingSearchText);
        } else if (activeTab === "1") {
          let new_page = page;
          if (campaigns.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1;
          }
          let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1&page_size=${sizePerPage}&page=${new_page}`;
          url = filter !== "" ? url + `${filter}` : url;
          loadData(url, sizePerPage, new_page, Search_Text);
        } else {
          let new_page = pausedpage;
          if (pausedcampaigns.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1;
          }
          let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2&page_size=${sizePerPage}&page=${new_page}`;
          url = filter !== "" ? url + `${filter}` : url;
          loadPausedData(url, sizePerPage, new_page, pausedSearchText);
        }
        setSuccess_confirm(true);
        setAlert_confirm(false);
        setDynamic_title("Deleted!");
        setDynamic_description("Campaign Has been deleted.");
        setSelected_id(null);
        setIsPending(false);
      }
    });
  }

  function handleTableChange(type, { page, sizePerPage, searchText }) {
    setLoadData(false);
    setCampaigns([]);
    let new_page = page;
    let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1&page_size=${sizePerPage}&&page=${new_page}`;
    url = filter !== "" ? url + `${filter}` : url;
    setTimeout(() => {
      window.scrollTo(0, 0);
      loadData(url, sizePerPage, new_page, searchText);
    }, 300);
  }

  function handlePendingTableChange(type, { page, sizePerPage, searchText }) {
    setTimeout(() => {
      let new_page = page;
      let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?&status=false&page_size=${pendingsizePerPage}&&page=${new_page}`;
      url = filter !== "" ? url + `${filter}` : url;
      window.scrollTo(0, 0);
      loadPendingData(url, pendingsizePerPage, new_page, searchText);
    }, 300);
  }

  function handlePausedTableChange(type, { page, sizePerPage, searchText }) {
    setTimeout(() => {
      let new_page = page;
      let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=2&page_size=${pausedsizePerPage}&&page=${new_page}`;
      url = filter !== "" ? url + `${filter}` : url;
      window.scrollTo(0, 0);
      loadPausedData(url, pausedsizePerPage, new_page, searchText);
    }, 300);
  }


  async function loadAllCampaigns() {
    if (totalSize > 1000) {
      return campaigns;
    } else {
      let url = `${BASE_URL}/api/tgcl/dashboard/contracts/?status=true&active=1&page_size=${totalSize}`;
      
      try {
        let response = await get(url, { headers: { "Content-Type": "application/json" } });
        return response.data.results;
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        return campaigns;
      }
    }
  }
  
  // async function handleDownloadExcel() {
  //   setDownloading(true)
  //   let data = [];
  //   const header = ["Contractor", "Category", "Assigned City", "Tracking Phone", "Zip", "Email"];
  
  //   const camps = await loadAllCampaigns();
  //   console.log(camps, "campscampscampscampscampscampscampscamps");
  
  //   if (camps.length > 0) {
  //     for (let i = 0; i < camps.length; i++) {
  //       data.push({
  //         "contractor": camps[i].contractor,
  //         "category": camps[i].category,
  //         "page_title": camps[i].page_title,
  //         "phone": camps[i].phone,
  //         "zip": camps[i].zip,
  //         "email": camps[i].email,
  //       });
  //     }
  //   }
  //   setDownloading(false)
  
  //   downloadExcel({
  //     fileName: `Campaigns ${moment().format('lll')}.xlsx`,
  //     sheet: "Campaigns",
  //     tablePayload: {
  //       header,
  //       body: data,
  //     },
  //   });
  // }


  async function handleDownloadExcel() {
    setDownloading(true);
    let data = [];
  
    // Define the header for the Excel sheet
    const header = ["Contractor", "Category", "Page Title", "Tracking Phone", "Zip", "Email"];
  
    // Load campaigns data
    const camps = await loadAllCampaigns();
    console.log(camps, "campscampscampscampscampscampscampscamps");
  
    // Check if there are campaigns and populate data
    if (camps.length > 0) {
      for (let i = 0; i < camps.length; i++) {
        data.push({
          "Contractor": camps[i].contractor,
          "Category": camps[i].category,
          "Page Title": camps[i].page_title,
          "Tracking Phone": camps[i].phone,
          "Zip": camps[i].zip,
          "Email": camps[i].email,
        });
      }
    }
  
    setDownloading(false);
  
    // Create the worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Campaigns");
  
    // Write the Excel file with a dynamic filename
    XLSX.writeFile(workbook, `Campaigns_${moment().format('lll')}.xlsx`);
  }



  const defaultSorted = [
    {
      dataField: "id_num",
      order: "desc",
    },
  ];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
    ],
  };

  const PendingpageOptions = {
    page: pendingpage,
    sizePerPage: pendingsizePerPage,
    totalSize: pendingtotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
    ],
  };

  const PausedpageOptions = {
    page: pausedpage,
    sizePerPage: pausedsizePerPage,
    totalSize: pausedtotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
    ],
  };

  if (filter !== "") {
    saveFilter_to_storage();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          breadcrumbItem="Campaigns"
          link="/dashboard"
        />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Approved</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">Pending</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block">Paused</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      data={campaigns}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={campaigns}
                          columns={activeColumn}
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: true,
                          }}
                          bootstrap5
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="9">
                                  <AvForm
                                    className="needs-validation"
                                    onValidSubmit={FilterData}
                                  >
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={search_by}
                                            onChange={(e) => {
                                              setSearchby(e);
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      {search_by.value ? (
                                        <Col sm="9">
                                          <Row>
                                            <Col sm="7">
                                              {search_by.value !== null ? (
                                                <Row>
                                                  <Col sm="12">
                                                    <div className="text-sm-end mb-2">
                                                      <AvField
                                                        name="search"
                                                        placeholder={
                                                          search_by.label
                                                        }
                                                        type="text"
                                                        errorMessage=""
                                                        value={search}
                                                        onChange={(e) =>
                                                          setSearch(
                                                            e.target.value
                                                          )
                                                        }
                                                        className="form-control"
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              ) : null}
                                            </Col>

                                            <Col sm="5">
                                              <Button
                                                type="submit"
                                                color="success"
                                                className="btn-rounded me-2"
                                              >
                                                Filter
                                              </Button>{" "}
                                              <Button
                                                type="button"
                                                color="primary"
                                                onClick={() => ClearFilter()}
                                                className="btn-rounded"
                                              >
                                                Clear
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  </AvForm>
                                </Col>

                                <Col sm="3">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        props.history.push("/new-campaign")
                                      }
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                      New Campaign
                                    </Button>
                                    {""}
                                    {downloading?
                                    <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded waves-effect me-2"
                                  >
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>{" "}
                                     Downloading
                                  </Button>:
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        handleDownloadExcel()
                                      }
                                    >
                                      <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                      Download
                                    </Button>}
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  remote
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  bordered={false}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  hover
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  onTableChange={handleTableChange}
                                  noDataIndication={NoDataIndication}
                                />
                              </div>
                              <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </TabPane>
                  <TabPane tabId="2">
                    <PaginationProvider
                      pagination={paginationFactory(PendingpageOptions)}
                      data={pendingcampaigns}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={pendingcampaigns}
                          columns={pendingColumn}
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: true,
                          }}
                          bootstrap5
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="9">
                                  <AvForm
                                    className="needs-validation"
                                    onValidSubmit={FilterData}
                                  >
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={search_by}
                                            onChange={(e) => {
                                              setSearchby(e);
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      {search_by.value ? (
                                        <Col sm="9">
                                          <Row>
                                            <Col sm="7">
                                              {search_by.value !== null ? (
                                                <Row>
                                                  <Col sm="12">
                                                    <div className="text-sm-end mb-2">
                                                      <AvField
                                                        name="search"
                                                        placeholder={
                                                          search_by.label
                                                        }
                                                        type="text"
                                                        errorMessage=""
                                                        className="form-control"
                                                        value={search}
                                                        onChange={(e) =>
                                                          setSearch(
                                                            e.target.value
                                                          )
                                                        }
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              ) : null}
                                            </Col>

                                            <Col sm="5">
                                              <Button
                                                type="submit"
                                                color="success"
                                                className="btn-rounded me-2"
                                              >
                                                Filter
                                              </Button>{" "}
                                              <Button
                                                type="button"
                                                color="primary"
                                                onClick={() => ClearFilter()}
                                                className="btn-rounded"
                                              >
                                                Clear
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  </AvForm>
                                </Col>

                                <Col sm="3">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        props.history.push("/new-campaign")
                                      }
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                      New Campaign
                                    </Button>{" "}
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  remote
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  bordered={false}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  hover
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  onTableChange={handlePendingTableChange}
                                />
                              </div>
                              <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </TabPane>
                  <TabPane tabId="3">
                    <PaginationProvider
                      pagination={paginationFactory(PausedpageOptions)}
                      data={pausedcampaigns}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={pausedcampaigns}
                          columns={pendingColumn}
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true,
                            cellEdit: true,
                          }}
                          bootstrap5
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="9">
                                  <AvForm
                                    className="needs-validation"
                                    onValidSubmit={FilterData}
                                  >
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={search_by}
                                            onChange={(e) => {
                                              setSearchby(e);
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      {search_by.value ? (
                                        <Col sm="9">
                                          <Row>
                                            <Col sm="7">
                                              {search_by.value !== null ? (
                                                <Row>
                                                  <Col sm="12">
                                                    <div className="text-sm-end mb-2">
                                                      <AvField
                                                        name="search"
                                                        placeholder={
                                                          search_by.label
                                                        }
                                                        type="text"
                                                        errorMessage=""
                                                        className="form-control"
                                                        value={search}
                                                        onChange={(e) =>
                                                          setSearch(
                                                            e.target.value
                                                          )
                                                        }
                                                        validate={{
                                                          required: {
                                                            value: true,
                                                          },
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              ) : null}
                                            </Col>

                                            <Col sm="5">
                                              <Button
                                                type="submit"
                                                color="success"
                                                className="btn-rounded me-2"
                                              >
                                                Filter
                                              </Button>{" "}
                                              <Button
                                                type="button"
                                                color="primary"
                                                onClick={() => ClearFilter()}
                                                className="btn-rounded"
                                              >
                                                Clear
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                      ) : null}
                                    </Row>
                                  </AvForm>
                                </Col>

                                <Col sm="3">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={() =>
                                        props.history.push("/new-campaign")
                                      }
                                    >
                                      <i className="mdi mdi-plus me-1" /> Add
                                      New Campaign
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  remote
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  bordered={false}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  hover
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  onTableChange={handlePausedTableChange}
                                />
                              </div>
                              <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleCampaignClicks(selected_id, isPending)}
            onCancel={() => {
              setAlert_confirm(false);
              setSelected_id(null);
              setIsPending(false);
            }}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false);
              setAlert_confirm(false);
              setIsPending(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Campaigns;
