import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, Label, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'moment-timezone';
import { AvForm, AvField } from "availity-reactstrap-validation"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";

import { downloadExcel } from "react-export-table-to-excel";
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as XLSX from 'xlsx';  // Import XLSX library


const TotalUsersReport = (props) => {

    const [reports, setreports] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [loaddata, setLoadData] = useState(false)
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD'))

    ValidateUser(props)

    const columns = [

        {
            dataField: "resource",
            text: "Resource",
            headerFormatter:HeaderFormatter,

        },
        {
            dataField: "count",
            text: "Count",
            headerFormatter:HeaderFormatter,


        },

    ]

    const header = ["Resource", "Count"];
    



    useEffect(() => {
        loadData(`${BASE_URL}/api/tgcl/dashboard/total-users/?startdate=${startdate}&enddate=${enddate}`)
        
    }, []);  
    
    function handleFilter(e, values){
        loadData(`${BASE_URL}/api/tgcl/dashboard/total-users/?startdate=${values.startdate}&enddate=${values.enddate}`)
        
      }


    function loadData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
        setreports(response.data)
        settotalSize((response.data).length)
        setLoadData(true)
        })
    }

    function NoDataIndication(){
        if (loaddata && totalSize === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
    }

    // function handleDownloadExcel() {
    //   let data = []
    //   for (let i=0; i<reports.length; i++){
    //     data.push({"resource":reports[i].resource, 'count': reports[i].count})
    //   }
    //   downloadExcel({
    //     fileName: "Media Partner Referral Report",
    //     sheet: "media-partner-referral-report",
    //     tablePayload: {
    //       header,
    //       // accept two different data structures
    //       body: data,
    //     },
    //   });
    // }
    

    function handleDownloadExcel() {
      let data = [];
  
      // Define the header for the Excel sheet
      const header = ["Resource", "Count"];
  
      // Loop through reports and prepare data
      for (let i = 0; i < reports.length; i++) {
          data.push({
              "Resource": reports[i].resource || "",  // Default to empty string if 'resource' is undefined
              "Count": reports[i].count || ""  // Default to empty string if 'count' is undefined
          });
      }
  
      // Create the worksheet from the data
      const worksheet = XLSX.utils.json_to_sheet(data);
  
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Media Partner Referral Report");
  
      // Write the Excel file with a dynamic filename
      XLSX.writeFile(workbook, `Media_Partner_Referral_Report_${moment().tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}.xlsx`);
  }


    const defaultSorted = [{
        dataField: 'resource',
        order: 'asc'
        }];


    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Dashboard" breadcrumbItem='Media Partner Referral Report' link="/dashboard" />
            <Row>
          <Col xs="12">
            <Card>
              <CardBody>
              <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                <Row>
                
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Row className="mb-3">
                          <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                          <div className="col-md-8">
                          <AvField
                            name="startdate"
                            placeholder="To Date"
                            type="date"
                            errorMessage="To Date"
                            className="form-control"
                            value={startdate}
                            onChange={(e)=> setStartDate(e.target.value)}
                            id="to_date"
                            
                          />
                        </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3">
                          <Label htmlFor="email_bcc" className="col-md-4 text-center col-form-label">To</Label>
                          <div className="col-md-8">
                          <AvField
                            name="enddate"
                            placeholder="To Date"
                            type="date"
                            errorMessage="To Date"
                            className="form-control"
                            value={enddate}
                            onChange={(e)=> setEnddate(e.target.value)}
                            id="to_date"
                          />
                        </div>
                        </Row>
                      </Col>
                      
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Button type="submit" color="success" className="btn-rounded me-2" >
                      Filter
                    </Button>
                    {" "}
                    {/* <Button type="button" color="success" className="btn-rounded me-2" onClick={()=> handleDownloadExcel()} >
                      Download
                    </Button> */}
                    <Button
                      type="button"
                      color="primary"
                      className="btn-rounded me-2"
                      onClick={() => handleDownloadExcel()}
                      >
                      <i className="mdi mdi-file-excel-outline me-1" />{" "}
                      Download
                    </Button>
                  </Col>
                  
                </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <div className="table-responsive">

                    <BootstrapTable
                    responsive
                    keyField="resource"
                    data={ reports }
                    columns={ columns }
                    bordered={false}
                    striped={true}
                    hover
                    defaultSorted={defaultSorted}
                    classes={
                        "table align-middle table-nowrap table-check"
                    }
                    headerWrapperClasses={"table-light"}
                    noDataIndication={() => NoDataIndication()}
                    />

                    </div>
          
                </CardBody>
                </Card>
            </Col>
            </Row>
        
            </div>

        </React.Fragment>
    )
}

export default TotalUsersReport
