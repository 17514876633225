import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, CardTitle, Spinner, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
  import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import moment from "moment-timezone";
import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";
import { downloadExcel } from "react-export-table-to-excel";
import * as XLSX from 'xlsx';  // Import XLSX library


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const MasterSoldReport = (props) => {

    const [reports, setreports] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [loaddata, setLoadData] = useState(false)
    const [membershipFee, setMembershipFee] = useState(0);
    const [monthlyFee, setMonthlyFee] = useState(0);

    ValidateUser(props)

    const columns = [
        {
            dataField: "id",
            text: "ID",
            // hidden:true
            formatter: (cellContent, row, index) => (
                index+1
              ),
            
        },
        {
            dataField: "contractor",
            text: "Contractor Name",
            headerFormatter:HeaderFormatter,
            sort: true,
            
        },
        {
            dataField: "page_title",
            text: "City Name",
            headerFormatter:HeaderFormatter,
            sort: true,

        },
        {
            dataField: "monthly_payment",
            text: "Monthly Fee",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
        {
            dataField: "membership_fee",
            text: "Membership Fee",
            headerFormatter:HeaderFormatter,
            sort: true,

        },
        {
            dataField: "zip",
            text: "Zip",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
        {
            dataField: "zone",
            text: "Zone",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
        {
            dataField: "start",
            text: "Start",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
                moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
              ),
        },
        {
            dataField: "exp",
            text: "Expire",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
                moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
              ),
        },
    ]

    const header = ["Contractor Name", "Page Title", "Membership Fee", 'Monthly Fee', 'Zip', 'Zone', 'Start', 'Expire' ];



    useEffect(() => {
        loadData(`${BASE_URL}/api/tgcl/dashboard/master-sold-report/`)
        
    }, []);   


    function loadData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
        setreports(response.data.data)
        settotalSize(response.data.count)
        setMembershipFee(response.data.total_membership_fee)
        setMonthlyFee(response.data.total_monthly_payment)
        setLoadData(true)
        })
    }

    function NoDataIndication(){
        if (loaddata && totalSize === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }if (loaddata && totalSize > 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
    }

    // function handleDownloadExcel() {
    //     let data = []
    //     for (let i=0; i<reports.length; i++){
    //       data.push({"contractor":reports[i].contractor, 'page_title': reports[i].page_title, 
    //                 'membership_fee':reports[i].membership_fee, 'monthly_payment':reports[i].monthly_payment,
    //                 'zip':reports[i].zip, 'zone':reports[i].zone,
    //                 'start':reports[i].start, 'exp':reports[i].exp})
    //     }
    //     downloadExcel({
    //       fileName: "Master Sold Report",
    //       sheet: "master-sold-report",
    //       tablePayload: {
    //         header,
    //         // accept two different data structures
    //         body: data,
    //       },
    //     });
    //   }
    

    function handleDownloadExcel() {
        let data = [];
    
        // Define the header for the Excel sheet
        const header = ["Contractor", "Page Title", "Membership Fee", "Monthly Payment", "Zip", "Zone", "Start", "Exp"];
    
        // Loop through reports and prepare data
        for (let i = 0; i < reports.length; i++) {
            data.push({
                "Contractor": reports[i].contractor || "",  // Default to empty string if 'contractor' is undefined
                "Page Title": reports[i].page_title || "",  // Default to empty string if 'page_title' is undefined
                "Membership Fee": reports[i].membership_fee || "",  // Default to empty string if 'membership_fee' is undefined
                "Monthly Payment": reports[i].monthly_payment || "",  // Default to empty string if 'monthly_payment' is undefined
                "Zip": reports[i].zip || "",  // Default to empty string if 'zip' is undefined
                "Zone": reports[i].zone || "",  // Default to empty string if 'zone' is undefined
                "Start": reports[i].start || "",  // Default to empty string if 'start' is undefined
                "Exp": reports[i].exp || ""  // Default to empty string if 'exp' is undefined
            });
        }
    
        // Create the worksheet from the data
        const worksheet = XLSX.utils.json_to_sheet(data);
    
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Master Sold Report");
    
        // Write the Excel file with a dynamic filename
        XLSX.writeFile(workbook, `Master_Sold_Report_${moment().tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}.xlsx`);
    }


    const defaultSorted = [{
        dataField: 'contractor',
        order: 'asc'
        }];

    const { SearchBar } = Search

        //pagination customization
    const pageOptions = {
        sizePerPage: reports.length,
        totalSize: reports.length, // replace later with size(Order),
        custom: true,
    }

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Dashboard" breadcrumbItem='Master Sold Report' link="/dashboard" />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="10">
                                    <Row>
                                        <Col sm="4">
                                            <CardTitle>{`Campaigns: ${totalSize} `}</CardTitle>  
                                        </Col>
                                        <Col sm="4">
                                            <CardTitle>{`Total Membership Fees: $${membershipFee} `}  </CardTitle>  
                                        </Col>
                                        <Col sm="4">
                                            <CardTitle>{`Total Monthly: $${monthlyFee} `} </CardTitle>  
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="2">
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={() => handleDownloadExcel()}
                                        >
                                        <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                                
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                    <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={reports}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                        keyField="id"
                        data={reports}
                        columns={columns}
                        bootstrap5
                        search
                        >
                        {toolkitProps => (
                            <React.Fragment>
                            
                            <Row className="mb-2">
                                <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                    <SearchBar
                                        {...toolkitProps.searchProps}
                                    />
                                    
                                    </div>
                                </div>
                                </Col>
                                <Col sm="8">
                                <div className="text-sm-end">
                                </div>
                                </Col>
                            </Row>
                            <div className="table-responsive">

                                <BootstrapTable
                                // remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                    "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => NoDataIndication()}
                                />
                                
                            </div>
                            {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                {...paginationProps}
                                />
                            </div> */}
                            <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                            </React.Fragment>
                        )}
                        </ToolkitProvider>
                    )}
                    </PaginationProvider>
                </CardBody>
                </Card>
            </Col>
            </Row>
        
            </div>

        </React.Fragment>
    )
}

export default MasterSoldReport
