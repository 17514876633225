import React, { useState, useEffect } from "react"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"


import { Row, Col, Card, CardBody, NavLink, NavItem, Nav, TabPane, TabContent, Spinner, Button } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator"
import { toast } from "react-toastify";
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { get, put, post, patch } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import moment from "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import * as XLSX from 'xlsx';  // Import XLSX library


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
    {
        label: "Search by",
        options: [

            { label: "Business Name", value: "bus_name" },
            { label: "Contractor POC", value: "contact_name" },
            { label: "Emails", value: "email" },
            { label: "Phone", value: "phone" },
            { label: "Date", value: "created" },
        ],
    },
];

const sortOptionGroup = [
    {
        label: "Order by",
        options: [
            { label: "Asc", value: "" },
            { label: "Desc", value: "-" },
        ],
    },
];


const PendingActionOption = [
    {
        label: "Order by",
        options: [
            { label: "Move to Approved", value: "approved" },
            { label: "Move to Archive", value: "archive" },
        ],
    },
];

const ApprovedActionOption = [
    {
        label: "Order by",
        options: [
            { label: "Move to Pending", value: "pending" },
            { label: "Move to Archive", value: "archive" },
        ],
    },
];

const ArchiveActionOption = [
    {
        label: "Order by",
        options: [
            { label: "Move to Approved", value: "approved" },
            { label: "Move to Pending", value: "pending" },
        ],
    },
];

const header = ["Contact Name", "Business Name", "Email", "Address", "State", "City", "Zip Code", "Phone"];

const EndorsmentListing = (props) => {

    const [activeTab, setactiveTab] = useState("2")
    const [contractors, setContractors] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [downloading, setDownloading] = useState(false)

    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [loaddata, setLoadData] = useState(false)

    const [loadPendingdata, setLoadPendingData] = useState(false)
    const [pendingContractors, setPendingContractors] = useState([]);
    const [pendingTotalSize, setPendingTotalSize] = useState(0);
    const [pendingPage, setPendingPage] = useState(1)


    const [actionApproved, setSetActionApproved] = useState({ label: "Select Action", value: null });
    const [actionPending, setSetActionPending] = useState({ label: "Select Action", value: null });
    const [actionArchived, setSetActionArchived] = useState({ label: "Select Action", value: null });


    const [loadArchivedata, setLoadArchiveData] = useState(false)
    const [archiveContractors, setArchiveContractors] = useState([]);
    const [archiveTotalSize, setArchiveTotalSize] = useState(0);
    const [archivePage, setArchivePage] = useState(1)
    const [ids, setIds] = useState([])
    const [pendingIds, setIdsPending] = useState([])
    const [archiveIds, setArchiveIds] = useState([])
    const [filter, setFilter] = useState("")
    const [search, setSearch] = useState("")
    const [search_by, setSearchby] = useState({ label: "Search By", value: null });
    const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))


    function toggle(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }


    const columns = [
        {
            dataField: "id",
            text: "ID",
            hidden: true,
        },
        {
            dataField: "bus_name",
            text: "Business Name",
            formatter: (cellContent, row) => (
                <Link to={`/contractor/${row.id}?type=endorsenemt`} className="me-3 text-dark">{(cellContent ? cellContent : "")}</Link>
            ),
            // sort: true,
            headerFormatter: HeaderFormatter,
        },
        // {
        //     dataField: "phone",
        //     text: "Tracking Phone",
        //     headerFormatter: HeaderFormatter,
        // },
        {
            dataField: "created",
            text: "Registration Date",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                cellContent ? moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY hh:mm A') : ""
            ),
        },
        {
            dataField: "contractor_type",
            text: "Endorsement",
            align: 'center',
            headerFormatter: HeaderFormatter,

            //   formatter: (cellContent, row) => (
            // <div className="checkbox disabled">

            formatter: (cellContent) => (
                cellContent === "ENDORSEMENT_PARTNER" ?


                    <i className="fa fa-check text-success font-size-18"></i>
                    :
                    <i className="fa fa-times text-danger font-size-18"></i>
            ),


            //   <label>
            //     <input type="checkbox" id="switch" defaultChecked={ cellContent===1?true:false } onChange={(e)=> handleChangeNotification(row.id,e.target.checked?1:0)} />
            //   </label>
            // </div>
            // ),
        },
        {
            dataField: "isactive",
            text: "Is Active",
            align: 'center',
            headerFormatter: HeaderFormatter,
            formatter: (cellContent) => (
                cellContent === 1 ?
                    <i className="fa fa-check text-success font-size-18"></i> :
                    <i className="fa fa-times text-danger font-size-18"></i>
            ),
        },
        {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            headerFormatter: HeaderFormatter,
            formatter: (cellContent, row) => (
                row.archive ?
                    <React.Fragment>
                        <Link to={`/contractor/${row.id}?type=endorsenemt`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Link to={`/contractor/${row.id}?type=endorsenemt`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                        <Link to="#" onClick={() => { setAlert_confirm(true); setSelected_id(row.id) }} className="text-warning"><i className="mdi mdi-archive font-size-18"></i></Link>
                    </React.Fragment>
            ),
        },
    ]

    useEffect(() => {
        if (localStorage.getItem("ContractorFilter")) {
            const obj = JSON.parse(localStorage.getItem("ContractorFilter"))
            setFilter(obj.filter)
            setFromDate(obj.fromDate)
            setToDate(obj.toDate)
            setOrder_by(obj.order_by)
            setSearch(obj.search)
            setSearchby(obj.search_by)
            loadData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}${obj.filter}`, sizePerPage, 1)
            loadPendingData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}${obj.filter}`, sizePerPage, 1)
            loadArchiveData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}${obj.filter}`, sizePerPage, 1)
        } else {

            loadData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}`, sizePerPage, 1)
            loadPendingData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&order_by=-created`, sizePerPage, 1)
            loadArchiveData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}`, sizePerPage, 1)

        }


    }, []);

    function FilterData(e, values) {
        let filter_data = ""
        if (!search_by.value) {
            toast.error("Select Search Field")
        } else {
            if (search_by.value === "created") {
                filter_data = `&from_date=${fromDate}&to_date=${toDate}`
            }
            else {
                filter_data = filter_data + `&${search_by.value}=${values.search}`
            }
            filter_data = filter_data + `&order_by=${order_by.value}${search_by.value}`
            setFilter(filter_data)
            loadData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}${filter_data}`, sizePerPage, 1)
            loadPendingData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}${filter_data}`, sizePerPage, 1)
            loadArchiveData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}${filter_data}`, sizePerPage, 1)

        }

    }

    function ClearFilter() {

        setFilter("")
        setSearch("")
        setSearchby({ label: "Search By", value: null });
        loadData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}`, sizePerPage, 1)
        loadPendingData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&order_by=-created`, sizePerPage, 1)
        loadArchiveData(`${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}`, sizePerPage, 1)
        localStorage.removeItem('ContractorFilter');
    }


    // function handleDownloadExcel() {
    //     let data = []
    //     for (let i = 0; i < contractors.length; i++) {
    //         data.push({
    //             "contact_name": contractors[i].contact_name,
    //             'bus_name': contractors[i].bus_name,
    //             'email': contractors[i].email,
    //             'address': contractors[i].address,
    //             'state': contractors[i].state ? contractors[i].state.state : "",
    //             'city': contractors[i].city ? contractors[i].city : "",
    //             'zip': contractors[i].zip ? contractors[i].zip : "",
    //             "endors": contractors[i].contractor_type === "ENDORSEMENT_PARTNER" ? true : false,
    //             'phone': contractors[i].phone ? contractors[i].phone : "",
    //         })
    //     }
    //     downloadExcel({
    //         fileName: `Contractors ${moment().tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}`,
    //         sheet: "contractors",
    //         tablePayload: {
    //             header,
    //             body: data,
    //         },
    //     });
    // }

    function handleDownloadExcel() {
        let data = [];
    
        // Define the header for the Excel sheet
        const header = [
            "Contact Name", "Business Name", "Email", "Address", 
            "State", "City", "Zip", "Endorsed", "Phone"
        ];
    
        // Loop through contractors and prepare data
        for (let i = 0; i < contractors.length; i++) {
            data.push({
                "Contact Name": contractors[i].contact_name || "",
                "Business Name": contractors[i].bus_name || "",
                "Email": contractors[i].email || "",
                "Address": contractors[i].address || "",
                "State": contractors[i].state ? contractors[i].state.state : "",
                "City": contractors[i].city || "",
                "Zip": contractors[i].zip || "",
                "Endorsed": contractors[i].contractor_type === "ENDORSEMENT_PARTNER" ? true : false,
                "Phone": contractors[i].phone || ""
            });
        }
    
        // Create the worksheet from the data
        const worksheet = XLSX.utils.json_to_sheet(data);
    
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");
    
        // Write the Excel file with a dynamic filename
        XLSX.writeFile(workbook, `Contractors_${moment().tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}.xlsx`);
    }



    function saveFilter_to_storage() {
        let filters = {
            "filter": filter,
            "order_by": order_by,
            "search_by": search_by,
            "fromDate": fromDate,
            "toDate": toDate,
            "search": search
        }
        localStorage.setItem("ContractorFilter", JSON.stringify(filters));
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    const selectRowPrnding = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelectPending,
        onSelectAll: handleOnSelectAllPending
    };


    const selectRowArchive = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelectArchive,
        onSelectAll: handleOnSelectAllArchive
    };

    function handleOnSelect(row, isSelect) {
        let id = []
        if (isSelect) {
            id = ids
            id.push(row.id)
        } else {
            for (let i = 0; i < ids.length; i++) {
                if (ids[i] !== row.id) {
                    id.push(ids[i])
                }
            }

        }
        setIds(id)
    }

    function handleOnSelectAll(isSelect, rows) {
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setIds(id)
        } else {
            setIds([])
        }
    }

    function handleOnSelectPending(row, isSelect) {
        let id = []
        if (isSelect) {
            id = pendingIds
            id.push(row.id)
        } else {
            for (let i = 0; i < pendingIds.length; i++) {
                if (pendingIds[i] !== row.id) {
                    id.push(pendingIds[i])
                }
            }

        }
        setIdsPending(id)
    }

    function handleOnSelectAllPending(isSelect, rows) {
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setIdsPending(id)
        } else {
            setIdsPending([])
        }
    }

    function handleOnSelectArchive(row, isSelect) {
        let id = []
        if (isSelect) {
            id = pendingIds
            id.push(row.id)
        } else {
            for (let i = 0; i < pendingIds.length; i++) {
                if (pendingIds[i] !== row.id) {
                    id.push(pendingIds[i])
                }
            }

        }
        setArchiveIds(id)
    }

    function handleOnSelectAllArchive(isSelect, rows) {
        if (isSelect) {
            let id = []
            for (let i = 0; i < rows.length; i++) {
                id.push(rows[i].id)
            }
            setArchiveIds(id)
        } else {
            setArchiveIds([])
        }
    }

    function loadData(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json' } })

        console.log(data, "DATA")
        data.then(response => {
            console.log(response.data, "DATA")
            setContractors(response.data.results)
            setTotalSize(response.data.count)
            setpage(page)
            setsizePerPage(sizePerPage)
            setLoadData(true)
        })
    }

    function loadPendingData(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json' } })
        data.then(response => {
            setPendingContractors(response.data.results)
            setPendingTotalSize(response.data.count)
            setPendingPage(page)
            setLoadPendingData(true)
        })
    }

    function loadArchiveData(url, sizePerPage, page) {
        let data = get(url, { headers: { 'Content-Type': 'application/json' } })
        data.then(response => {
            setArchiveContractors(response.data.results)
            setArchiveTotalSize(response.data.count)
            setArchivePage(page)
            setLoadArchiveData(true)
        })
    }

    function NoDataIndication() {
        if (loaddata && contractors.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )

        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )

        }
    }

    function PendingNoDataIndication() {
        if (loadPendingdata && pendingContractors.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )
        }
    }

    function ArchiveNoDataIndication() {
        if (loadArchivedata && archiveContractors.length === 0) {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
            )
        } else {
            return (

                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
            )
        }
    }

    function handleChangeNotification(id, status) {
        let data = { "callemail": status }
        put(`${BASE_URL}/api/tgcl/all-contractors/${id}/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
            .then(res => {
                if (res.status >= 400) {
                    console.log(res.data, "-error")
                }
            }
            )
    }

    function HandleAction() {
        if (activeTab === "2") {
            if (actionApproved.value === "pending") {
                ids.length > 0 ? MovetoPending("approved") : toast.error("Select Contractors")
            } else if (actionApproved.value === "archive") {
                ids.length > 0 ? MovetoArchive("approved") : toast.error("Select Contractors")
            }
        } else if (activeTab === '1') {
            if (actionPending.value === "approved") {
                pendingIds.length > 0 ? MovetoActive("pending") : toast.error("Select Contractors")
            } else if (actionPending.value === "archive") {
                pendingIds.length > 0 ? MovetoArchive("pending") : toast.error("Select Contractors")
            }
        } else if (activeTab === '3') {
            if (actionArchived.value === "pending") {
                archiveIds.length > 0 ? MovetoPending("archive") : toast.error("Select Contractors")
            } else if (actionArchived.value === "approved") {
                archiveIds.length > 0 ? MovetoActive("archive") : toast.error("Select Contractors")
            }
        }

    }

    function handleContractorClick(id) {
        let data = { "archive": true }
        patch(`${BASE_URL}/api/tgcl/contractor/${id}/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
            .then(res => {
                if (res.status >= 400) {
                    console.log("error")
                }
                else {
                    if (activeTab === "2") {
                        let new_page = page
                        if (contractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadData(url, sizePerPage, new_page)

                    } else if (activeTab === "1") {
                        let new_page = pendingPage
                        if (pendingContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url

                        loadPendingData(url, sizePerPage, new_page)

                    } else {
                        let new_page = archivePage
                        if (archiveContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadArchiveData(url, sizePerPage, new_page)

                    }

                    setSuccess_confirm(true)
                    setAlert_confirm(false)
                    setDynamic_title('Archived!')
                    setDynamic_description("Contractor Has been archived.")
                    setSelected_id(null)
                }
            }
            )
    }

    function MovetoArchive(value) {
        let archive_ids = []
        if (value === 'approved') {
            archive_ids = ids
        } else {
            archive_ids = pendingIds
        }
        let data = { 'ids': archive_ids }

        post(`${BASE_URL}/api/tgcl/contractor-bulk-archive/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false },
        )
            .then(res => {
                if (res.status >= 400) {
                    console.log(res, "error")
                    toast.error("Somthing Went Wrong")
                }
                else {
                    if (activeTab === "2") {
                        let new_page = page
                        if (contractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadData(url, sizePerPage, new_page)
                        setIds([])
                        setContractors([])

                    } else if (activeTab === "1") {
                        let new_page = pendingPage
                        if (pendingContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url

                        loadPendingData(url, sizePerPage, new_page)
                        setIdsPending([])
                        setPendingContractors([])

                    }
                    let new_page = archivePage
                    if (archiveContractors.length < 2) {
                        new_page = new_page > 1 ? new_page - 1 : 1
                    }
                    let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}&page=${new_page}`
                    url = filter !== "" ? url + `${filter}` : url
                    loadArchiveData(url, sizePerPage, new_page)

                }
            }
            )

    }

    function MovetoPending(value) {
        let archive_ids = []
        if (value === 'approved') {
            archive_ids = ids
        } else {
            archive_ids = archiveIds
        }
        let data = { 'ids': archive_ids }

        post(`${BASE_URL}/api/tgcl/contractor-bulk-pending/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false },
        )
            .then(res => {
                if (res.status >= 400) {
                    console.log(res, "error")
                    toast.error("Somthing Went Wrong")
                }
                else {
                    if (activeTab === "2") {
                        let new_page = page
                        if (contractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadData(url, sizePerPage, new_page)
                        setIds([])
                        setContractors([])

                    } else if (activeTab === "3") {
                        let new_page = archivePage
                        if (archiveContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadArchiveData(url, sizePerPage, new_page)
                        setArchiveContractors([])
                        setArchiveIds([])

                    }
                    let new_page = pendingPage
                    if (pendingContractors.length < 2) {
                        new_page = new_page > 1 ? new_page - 1 : 1
                    }
                    let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
                    url = filter !== "" ? url + `${filter}` : url

                    loadPendingData(url, sizePerPage, new_page)

                }
            }
            )

    }

    function MovetoActive(value) {

        let archive_ids = []
        if (value === 'pending') {
            archive_ids = pendingIds
        } else {
            archive_ids = archiveIds
        }
        let data = { 'ids': archive_ids }

        post(`${BASE_URL}/api/tgcl/contractor-bulk-approved/`, data,
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false },
        )
            .then(res => {
                if (res.status >= 400) {
                    console.log(res, "error")
                    toast.error("Somthing Went Wrong")
                }
                else {
                    if (activeTab === "1") {
                        let new_page = pendingPage
                        if (pendingContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url

                        loadPendingData(url, sizePerPage, new_page)
                        setIdsPending([])
                        setPendingContractors([])

                    } else if (activeTab === "3") {
                        let new_page = archivePage
                        if (archiveContractors.length < 2) {
                            new_page = new_page > 1 ? new_page - 1 : 1
                        }
                        let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}&page=${new_page}`
                        url = filter !== "" ? url + `${filter}` : url
                        loadArchiveData(url, sizePerPage, new_page)
                        setArchiveContractors([])
                        setArchiveIds([])

                    }
                    let new_page = page
                    if (contractors.length < 2) {
                        new_page = new_page > 1 ? new_page - 1 : 1
                    }
                    let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
                    url = filter !== "" ? url + `${filter}` : url
                    loadData(url, sizePerPage, new_page)

                }
            }
            )

    }

    function handleTableChange(type, { page, sizePerPage, }) {
        setTimeout(() => {
            setLoadData(false)
            setContractors([])
            let new_page = page
            let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
                url = `${url}${filter}`
            }
            window.scrollTo(0, 0)
            loadData(url, sizePerPage, new_page)

        }, 2000);
    }

    function handlePendingTableChange(type, { page, sizePerPage, }) {
        setTimeout(() => {
            setLoadPendingData(false)
            setPendingContractors([])

            let new_page = page
            let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}&order_by=-created`
            if (filter !== "") {
                url = `${url}${filter}`
            }
            window.scrollTo(0, 0)
            loadPendingData(url, sizePerPage, new_page)

        }, 2000);
    }

    function handleArchiveTableChange(type, { page, sizePerPage, }) {
        setTimeout(() => {
            setLoadArchiveData(false)
            setArchiveContractors([])

            let new_page = page
            let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== "") {
                url = `${url}${filter}`
            }
            window.scrollTo(0, 0)
            loadArchiveData(url, sizePerPage, new_page)

        }, 2000);
    }

    async function loadAllContractors() {
        if (totalSize > 1000) {
            console.log(contractors, "contractors response")

            return contractors;
        } else {
            let url = `${BASE_URL}/api/tgcl/all-contractors/?contractor_type=ENDORSEMENT_PARTNER&approved=true&archive=false&page_size=${totalSize}`;

            try {
                let response = await get(url, { headers: { "Content-Type": "application/json" } });
                console.log(response, "response")
                return response.data.results;

            } catch (error) {
                console.error("Error fetching Contractors:", error);
                return contractors;
            }
        }
    }

    // async function handleDownloadExcel() {
    //     setDownloading(true)
    //     let data = [];
    //     const header = ["Business Name", "Contractor Name", "Tracking Phone", "Registration Date", "City", "Address", "Zip", "Email"];

    //     const contrat = await loadAllContractors();
    //     for (let i = 0; i < contrat.length; i++) {
    //         data.push({
    //             "bus_name": contrat[i].bus_name,
    //             "contact_name": contrat[i].bus_name,
    //             "phone": contrat[i].phone,
    //             "created": contrat[i].created ? moment(contrat[i].created).tz("America/Chicago").format('MM-DD-YYYY hh:mm A') : "",
    //             "endors": contrat[i].contractor_type = "ENDORSEMENT_PARTNER" ? true : false,


    //             "city": contrat[i].city,
    //             "address": contrat[i].address,
    //             "zip": contrat[i].zip,
    //             "email": contrat[i].email,
    //         });
    //     }
    //     setDownloading(false)

    //     downloadExcel({
    //         fileName: `Contractors ${moment().format('lll')}.xlsx`,
    //         sheet: "contractors",
    //         tablePayload: {
    //             header,
    //             body: data,
    //         },
    //     });
    // }

    async function handleDownloadExcel() {
        setDownloading(true);  // Set downloading flag to true
    
        const header = ["Business Name", "Contractor Name", "Tracking Phone", "Registration Date", "City", "Address", "Zip", "Email"];
        let data = [];  // Array to store contractor data
    
        // Fetch contractors data
        const contrat = await loadAllContractors();
    
        // Populate the data array with contractor details
        for (let i = 0; i < contrat.length; i++) {
            data.push({
                "Business Name": contrat[i].bus_name || "",  // Use empty string if bus_name is undefined
                "Contractor Name": contrat[i].contact_name || "",  // Use empty string if contact_name is undefined
                "Tracking Phone": contrat[i].phone || "",  // Use empty string if phone is undefined
                "Registration Date": contrat[i].created ? moment(contrat[i].created).tz("America/Chicago").format('MM-DD-YYYY hh:mm A') : "",  // Format date if available
                "City": contrat[i].city || "",  // Use empty string if city is undefined
                "Address": contrat[i].address || "",  // Use empty string if address is undefined
                "Zip": contrat[i].zip || "",  // Use empty string if zip is undefined
                "Email": contrat[i].email || "",  // Use empty string if email is undefined
            });
        }
    
        setDownloading(false);  // Set downloading flag to false once data is prepared
    
        // Create the worksheet from the data
        const worksheet = XLSX.utils.json_to_sheet(data, { header });
    
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");
    
        // Generate the file with a dynamic filename using the current date
        XLSX.writeFile(workbook, `Contractors_${moment().format('YYYY-MM-DD_hh-mm-ss_A')}.xlsx`);
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc'
    }];

    //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    }

    const PendingpageOptions = {
        page: pendingPage,
        sizePerPage: sizePerPage,
        totalSize: pendingTotalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    }

    const ArchivepageOptions = {
        page: archivePage,
        sizePerPage: sizePerPage,
        totalSize: archiveTotalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [{
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    }

    if (filter !== "") {
        saveFilter_to_storage()
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Dashboard" breadcrumbItem='Contractors' link="/dashboard" />
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Approved</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                toggle("1")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                            <span className="d-none d-sm-block">Pending</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3",
                                            })}
                                            onClick={() => {
                                                toggle("3")
                                            }}
                                        >
                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                            <span className="d-none d-sm-block">Archive</span>
                                        </NavLink>
                                    </NavItem>

                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId="2">
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            data={contractors}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={contractors}
                                                    columns={columns}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>

                                                            <Row className="mb-2">
                                                                <Col sm="9">
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={actionApproved}
                                                                                    onChange={(e) => {
                                                                                        setSetActionApproved(e)
                                                                                    }}
                                                                                    options={ApprovedActionOption}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            <Button type="button" color="primary" onClick={() => HandleAction()} className="btn-rounded me-2" >
                                                                                Apply
                                                                            </Button>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={search_by}
                                                                                    onChange={(e) => {
                                                                                        setSearchby(e)
                                                                                    }}
                                                                                    options={optionGroup}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>


                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="text-sm-end">

                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={() => props.history.push('/new-contractor?type=endorsenemt')}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            Add New Contractor
                                                                        </Button>{" "}
                                                                        {downloading ?
                                                                            <Button
                                                                                type="button"
                                                                                color="primary"
                                                                                className="btn-rounded waves-effect me-2"
                                                                            >
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>{" "}
                                                                                Downloading
                                                                            </Button> :
                                                                            <Button
                                                                                type="button"
                                                                                color="primary"
                                                                                className="btn-rounded mb-2 me-2"
                                                                                onClick={() =>
                                                                                    handleDownloadExcel()
                                                                                }
                                                                            >
                                                                                <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                                                                Download
                                                                            </Button>}
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value === "created" ?
                                                                                        <Row>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="from_date"
                                                                                                        placeholder="Received From"
                                                                                                        type="date"
                                                                                                        errorMessage="From Date"
                                                                                                        className="form-control"
                                                                                                        value={fromDate}
                                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                                        id="from_date"
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="to_date"
                                                                                                        placeholder="Category"
                                                                                                        type="date"
                                                                                                        errorMessage="To Date"
                                                                                                        className="form-control"
                                                                                                        value={toDate}
                                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                                        id="to_date"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : search_by.value !== null ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            errorMessage=""
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                            : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">


                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>

                                                                                </Col>
                                                                            </Row>
                                                                        </Col>

                                                                        : null}
                                                                </AvForm>
                                                            </Row>

                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handleTableChange}
                                                                    noDataIndication={() => NoDataIndication()}
                                                                    selectRow={selectRow}


                                                                />

                                                            </div>
                                                            <Row>
                                                                <div className="mb-3">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}

                                                                    />
                                                                </div>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </TabPane>
                                    <TabPane tabId="1" >
                                        <PaginationProvider
                                            pagination={paginationFactory(PendingpageOptions)}
                                            data={pendingContractors}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={pendingContractors}
                                                    columns={columns}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>

                                                            <Row className="mb-2">
                                                                <Col sm="9">
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={actionPending}
                                                                                    onChange={(e) => {
                                                                                        setSetActionPending(e)
                                                                                    }}
                                                                                    options={PendingActionOption}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            <Button type="button" color="primary" onClick={() => HandleAction()} className="btn-rounded me-2" >
                                                                                Apply
                                                                            </Button>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={search_by}
                                                                                    onChange={(e) => {
                                                                                        setSearchby(e)
                                                                                    }}
                                                                                    options={optionGroup}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>


                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="text-sm-end">

                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={() => props.history.push('/new-contractor')}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            Add New Contractor
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value === "created" ?
                                                                                        <Row>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="from_date"
                                                                                                        placeholder="Received From"
                                                                                                        type="date"
                                                                                                        errorMessage="From Date"
                                                                                                        className="form-control"
                                                                                                        value={fromDate}
                                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                                        id="from_date"
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="to_date"
                                                                                                        placeholder="Category"
                                                                                                        type="date"
                                                                                                        errorMessage="To Date"
                                                                                                        className="form-control"
                                                                                                        value={toDate}
                                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                                        id="to_date"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : search_by.value !== null ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            errorMessage=""
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}
                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                            : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">


                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>

                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}
                                                                </AvForm>
                                                            </Row>
                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handlePendingTableChange}
                                                                    noDataIndication={() => PendingNoDataIndication()}
                                                                    selectRow={selectRowPrnding}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="mb-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}

                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>

                                    </TabPane>
                                    <TabPane tabId="3" >
                                        <PaginationProvider
                                            pagination={paginationFactory(ArchivepageOptions)}
                                            data={archiveContractors}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    data={archiveContractors}
                                                    columns={columns}
                                                    remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                                                    bootstrap5
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>

                                                            <Row className="mb-2">
                                                                <Col sm="9">
                                                                    <Row className="mb-2">
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={actionArchived}
                                                                                    onChange={(e) => {
                                                                                        setSetActionArchived(e)
                                                                                    }}
                                                                                    options={ArchiveActionOption}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm="2">
                                                                            <Button type="button" color="primary" onClick={() => HandleAction()} className="btn-rounded me-2" >
                                                                                Apply
                                                                            </Button>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <div className="text-sm-start mb-2">
                                                                                <Select
                                                                                    value={search_by}
                                                                                    onChange={(e) => {
                                                                                        setSearchby(e)
                                                                                    }}
                                                                                    options={optionGroup}
                                                                                    classNamePrefix="select2-selection"
                                                                                />
                                                                            </div>


                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="text-sm-end">

                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            className="btn-rounded mb-2 me-2"
                                                                            onClick={() => props.history.push('/new-contractor')}
                                                                        >
                                                                            <i className="mdi mdi-plus me-1" />{" "}
                                                                            Add New Contractor
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                                                    {search_by.value ?
                                                                        <Col sm="9">
                                                                            <Row>
                                                                                <Col sm="5">
                                                                                    {search_by.value === "created" ?
                                                                                        <Row>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="from_date"
                                                                                                        placeholder="Received From"
                                                                                                        type="date"
                                                                                                        errorMessage="From Date"
                                                                                                        className="form-control"
                                                                                                        value={fromDate}
                                                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                                                        id="from_date"
                                                                                                    />
                                                                                                </div>

                                                                                            </Col>
                                                                                            <Col sm="6">
                                                                                                <div className="text-sm-end mb-2">
                                                                                                    <AvField
                                                                                                        name="to_date"
                                                                                                        placeholder="Category"
                                                                                                        type="date"
                                                                                                        errorMessage="To Date"
                                                                                                        className="form-control"
                                                                                                        value={toDate}
                                                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                                                        id="to_date"
                                                                                                    />
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        : search_by.value !== null ?
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="text-sm-end mb-2">
                                                                                                        <AvField
                                                                                                            name="search"
                                                                                                            placeholder={search_by.label}
                                                                                                            type="text"
                                                                                                            errorMessage=""
                                                                                                            value={search}
                                                                                                            onChange={(e) => setSearch(e.target.value)}
                                                                                                            className="form-control"
                                                                                                            validate={{ required: { value: true } }}

                                                                                                        />
                                                                                                    </div>

                                                                                                </Col>

                                                                                            </Row>

                                                                                            : null}

                                                                                </Col>
                                                                                <Col sm="3">
                                                                                    <div className="text-sm-start mb-2">
                                                                                        <Select
                                                                                            value={order_by}
                                                                                            onChange={(e) => {
                                                                                                setOrder_by(e)
                                                                                            }}
                                                                                            options={sortOptionGroup}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                                                        Filter
                                                                                    </Button>
                                                                                    {" "}
                                                                                    <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                                                                        Clear
                                                                                    </Button>

                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        : null}

                                                                </AvForm>
                                                            </Row>
                                                            {/* <Row>
                                <Col sm="4" className="mb-2">
                                  <Button type="button" color="primary" onClick={()=>{archiveIds.length>0?MovetoActive("archive"):toast.error("Select Contractors")}} className="btn-rounded me-2" >
                                    Approved
                                  </Button>
                                  {" "}
                                  <Button type="button" color="primary" onClick={()=>{archiveIds.length>0?MovetoPending("archive"):toast.error("Select Contractors")}} className="btn-rounded me-2" >
                                    Pending
                                  </Button>
                                </Col>
                              </Row>
                               */}
                                                            <div className="table-responsive">

                                                                <BootstrapTable
                                                                    remote
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    hover
                                                                    classes={
                                                                        "table align-middle table-nowrap table-check"
                                                                    }
                                                                    headerWrapperClasses={"table-light"}
                                                                    onTableChange={handleArchiveTableChange}
                                                                    noDataIndication={() => ArchiveNoDataIndication()}
                                                                    selectRow={selectRowArchive}
                                                                />

                                                            </div>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <div className="my-3">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}

                                                                        />
                                                                    </div>
                                                                    <div className="pagination pagination-rounded mb-2">
                                                                        <PaginationTotalStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                                <Col sm={6}>
                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>

                                                                </Col>
                                                            </Row>


                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>

                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {alert_confirm ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => handleContractorClick(selected_id)}
                        onCancel={() => {
                            setAlert_confirm(false)
                            setSelected_id(null)
                        }
                        }
                    >
                        {/* You won't be able to revert this! */}
                    </SweetAlert>
                ) : null}

                {success_confirm ? (
                    <SweetAlert
                        success
                        title={dynamic_title}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccess_confirm(false)
                            setAlert_confirm(false)
                        }
                        }
                    >
                        s{dynamic_description}
                    </SweetAlert>
                )
                    : null
                }

            </div>
        </React.Fragment>
    )
}

export default EndorsmentListing
